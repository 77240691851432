<template>
  <div>
    <div class="row">
      <div class="col-xxl-8">
        <div class="row">
          <div class="col-xl-6">
            <div class="card card-custom gutter-b example example-compact" v-if="isReady">
              <!--begin::Header-->
              <div class="card-header pb-0">
                <div class="card-title">
                  <!---->
                  <h3 class="card-label">{{ $t('OrganizationChart.post_detail') }} (Thai)</h3>
                </div>
                <div class="card-toolbar">
                  <div class="example-tools justify-content-center"></div>
                </div>
              </div>
              <!--end::Header-->
              <!--begin::Body-->
              <div class="card-body pt-3 pb-0">
                <b-form-group id="fieldset-1" description="" :label="$t('OrganizationChart.title')" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-model="post.name_th"
                    trim
                    placeholder="untitle"
                    :state="!(post.name_th == '')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group id="fieldset-1" description="" :label="$t('OrganizationChart.role')" label-for="input-1">
                  <b-form-input id="input-1" v-model="post.role_th" trim placeholder="untitle"></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="card card-custom gutter-b example example-compact" v-if="isReady">
              <!--begin::Header-->
              <div class="card-header pb-0">
                <div class="card-title">
                  <!---->
                  <h3 class="card-label">{{ $t('OrganizationChart.post_detail') }} (English)</h3>
                </div>
                <div class="card-toolbar">
                  <div class="example-tools justify-content-center"></div>
                </div>
              </div>
              <!--end::Header-->
              <!--begin::Body-->
              <div class="card-body pt-3 pb-0">
                <b-form-group id="fieldset-1" description="" :label="$t('OrganizationChart.title')" label-for="input-1">
                  <b-form-input
                    id="input-1"
                    v-model="post.name_en"
                    trim
                    placeholder="untitle"
                    :state="!(post.name_en == '')"
                  ></b-form-input>
                </b-form-group>
                <b-form-group id="fieldset-1" description="" :label="$t('OrganizationChart.role')" label-for="input-1">
                  <b-form-input id="input-1" v-model="post.role_en" trim placeholder="untitle"></b-form-input>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>

        <b-overlay :show="isSaving" no-wrap> </b-overlay>
      </div>

      <div class="col-xxl-4">
        <div class="card card-custom gutter-b" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">
                {{ $t('OrganizationChart.publish') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" label="สถานะ  " label-for="input-1">
              <b-form-select v-model="post.status" :options="status_list"></b-form-select>
            </b-form-group>
            <b-button variant="primary" class="mb-5" block @click="savePost" v-if="post.status == 0">Save</b-button>
            <b-button variant="success" class="mb-5" block @click="savePost" v-else>Publish</b-button>
          </div>
          <!--end::Body-->
          <b-overlay :show="isSaving" no-wrap> </b-overlay>
        </div>
        <div class="card card-custom gutter-b" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">{{ $t('OrganizationChart.banner') }}</h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" description="" label="" label-for="input-1" class="pt-3">
              <div id="preview">
                <b-img v-if="hasImage" :src="imageSrc" class="mb-3" fluid block rounded> </b-img>
                <b-img v-if="post.image_path && !hasImage" :src="post.image_path" class="mb-3" fluid block rounded>
                </b-img>
              </div>
              <b-button v-if="hasImage" variant="danger" class="btn-sm mb-5" @click="clearImage" block>Delete</b-button>
              <b-form-file
                accept="image/jpeg, image/png"
                v-model="pictureFile"
                placeholder="กรุณาเลือกไฟล์"
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </div>
          <!--end::Body-->
          <b-overlay :show="isSaving" no-wrap> </b-overlay>
        </div>

        <div class="card card-custom gutter-b example example-compact" v-if="isReady">
          <!--begin::Header-->
          <div class="card-header pb-0">
            <div class="card-title">
              <!---->
              <h3 class="card-label">
                {{ $t('OrganizationChart.sorting') }}
              </h3>
            </div>
            <div class="card-toolbar">
              <div class="example-tools justify-content-center"></div>
            </div>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <b-form-group id="fieldset-1" description="" :label="$t('OrganizationChart.row')" label-for="input-1">
              <b-form-select v-model="post.row" :options="row_list"></b-form-select>
            </b-form-group>
            <b-form-group id="fieldset-1" description="" :label="$t('OrganizationChart.sort')" label-for="input-1">
              <b-form-input id="input-1" v-model="post.sort" trim placeholder="untitle"></b-form-input>
            </b-form-group>
          </div>
          <b-overlay :show="isSaving" no-wrap> </b-overlay>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {SAVE_POST, QUERY, GET_POSITION, UPLOAD_FILE} from '../services.js';

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: 'List',
  components: {},
  data() {
    return {
      isReady: false,
      isSaving: false,
      isUploading: false,
      mode: null,
      post: {
        id: 0,
        name_th: '',
        role_th: '',
        name_en: '',
        role_en: '',
        sort: 0,
        row: 0,
        image_path: '',
        status: 0,
      },
      pictureFile: null,
      imageSrc: null,
      status_list: [
        {
          value: 0,
          text: 'Draft',
        },
        {
          value: 1,
          text: 'Published',
        },
      ],
      row_list: [
        {
          value: 1,
          text: 'Row 1',
        },
        {
          value: 2,
          text: 'Row 2',
        },
        {
          value: 3,
          text: 'Row 3',
        },
        {
          value: 4,
          text: 'Row 4',
        },
        {
          value: 5,
          text: 'Row 5',
        },
        {
          value: 6,
          text: 'Row 6',
        },
      ],
    };
  },
  mounted() {
    //get category
    this.getData();

    this.$store.dispatch(SET_BREADCRUMB, [{title: 'Organization Chart', route: '../'}, {title: 'Edit'}]);
  },
  methods: {
    getData() {
      if (this.$route.name == 'organization_chart.edit') {
        this.mode = 'edit';
        this.id = this.$route.params.id;
        this.$store
          .dispatch(QUERY, {id: this.id})
          // go to which page after successfully login
          .then((res) => {
            this.post = res.data[0];

            this.isReady = true;
          })
          .catch((error) => {
            // console.log(error);
          });
      } else {
        this.isReady = true;
        this.mode = 'add';
        this.post.row = this.$route.params.row;
      }
    },
    savePost(mode) {
      return new Promise((resolve, reject) => {
        if (this.post.name_th == '' || this.post.name_en == '') {
          this.notify('danger', 'Error!', 'Please complete the form');
          reject('Please complete the form');
        } else {
          this._savePost(mode);
          resolve();
        }
      });
    },
    _savePost(mode) {
      this.isSaving = true;
      this.$store
        .dispatch(SAVE_POST, this.post)
        // go to which page after successfully login
        .then((res) => {
          if (res.status) {
            this.post = res.data[0];

            //upload banner
            if (this.pictureFile) {
              let formData = new FormData();
              formData.append('file', this.pictureFile);
              this.pictureFile = null;
              this.$store
                .dispatch(UPLOAD_FILE, formData)
                // go to which page after successfully login
                .then((res) => {
                  if (res.status) {
                    this.post.image_path = res.data.image_path;
                  }
                  // console.log('Upload Banner');
                  this.isSaving = false;
                  this.pictureFile = null;
                  //Update image path
                  this.savePost('update_image');
                })
                .catch((error) => {
                  this.isSaving = false;
                  // console.log(error);
                });
            }

            // this.uploadFile();
            if (this.$route.name == 'organization_chart.add') {
              this.$router.push({
                name: 'organization_chart.edit',
                params: {id: this.post.id},
              });
            }
          }
          // console.log('Save post');
          this.isSaving = false;
          if (mode != 'update_image') {
            this.notify('success', 'Successfully!', 'Post Saved.');
            if (this.post.status == 1) {
              this.$router.push({
                name: 'organization_chart.list',
              });
            }
          }
        })
        .catch((error) => {
          this.isSaving = false;
          // console.log(error);
        });
    },
    clearImage() {
      this.pictureFile = null;
    },
    notify(variant = null, title, text) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
  },
  computed: {
    hasImage() {
      return !!this.pictureFile;
    },
  },
  watch: {
    pictureFile: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
